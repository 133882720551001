import PageComponent from "../js/PageComponent";

export const pageComponents = {
  // PageComponent takes in the following values
  // name - The name of the page component, which must match the name of the file added in the Web_UI/app/src/pages folder.
  // defaultRedirect - The default redirect URL for the page. This will be overwritten by the name content item created in Umbraco.
  // loadUser - Set to true if the page requires user state to be loaded.
  // loadBooking - Set to true if the page requires booking state to be loaded.
  // loadChannel - Set to true if the page requires channel state to be loaded.
  AccountAddress: new PageComponent(
    "AccountAddress",
    "/account-address",
    true,
    true,
    true
  ),
  AccountCoupons: new PageComponent(
    "AccountCoupons",
    "/account-coupons",
    true,
    true,
    true
  ),
  AccountDonations: new PageComponent(
    "AccountDonations",
    "/account-donations",
    true,
    true,
    true
  ),
  AccountMemberships: new PageComponent(
    "AccountMemberships",
    "/account-memberships",
    true,
    true,
    true
  ),
  AccountVouchers: new PageComponent(
    "AccountVouchers",
    "/account-vouchers",
    true,
    true,
    true
  ),
  AdditionalProducts: new PageComponent(
    "Additional",
    "/additional",
    true,
    true,
    true
  ),
  AssignPurchaser: new PageComponent(
    "AssignPurchaser",
    "/assign-purchaser",
    true,
    true,
    true
  ),
  Beneficiaries: new PageComponent(
    "Beneficiaries",
    "/beneficiaries",
    true,
    true,
    true
  ),
  BookingAuthentication: new PageComponent(
    "BookingAuthentication",
    "/booking-authentication",
    true,
    true,
    true
  ),
  BookingHistory: new PageComponent(
    "BookingHistory",
    "/booking-history",
    true,
    true,
    true
  ),
  BookingLogin: new PageComponent(
    "BookingLogin",
    "/booking-login",
    true,
    true,
    true
  ),
  BookingQuestions: new PageComponent(
    "BookingQuestions",
    "/booking-questions",
    true,
    true,
    true
  ),
  BookingWidget: new PageComponent(
    "BookingWidget",
    "",
    new URLSearchParams(window.location.search).get("show")
      ? true
      : false,
    new URLSearchParams(window.location.search).get("show")
      ? true
      : false,
    true
  ),
  ChangePassword: new PageComponent(
    "ChangePassword",
    "/change-password",
    true,
    true,
    true
  ),
  Confirmation: new PageComponent(
    "Confirmation",
    "/confirmation",
    true,
    true,
    true
  ),
  Content: new PageComponent("Content", "", true, true, true),
  Coupons: new PageComponent("Coupons", "/coupons", true, true, true),
  CreatePassword: new PageComponent(
    "CreatePassword",
    "/create-password",
    true,
    true,
    true
  ),
  Delivery: new PageComponent(
    "Delivery",
    "/delivery",
    true,
    true,
    true
  ),
  Donation: new PageComponent(
    "Donation",
    "/donation",
    true,
    true,
    true
  ),
  ForgottenPassword: new PageComponent(
    "ForgottenPassword",
    "/forgotten-password",
    true,
    true,
    true
  ),
  GiftAid: new PageComponent(
    "GiftAid",
    "/gift-aid",
    true,
    true,
    true
  ),
  Login: new PageComponent("Login", "/login", true, true, true),
  ManageSubscriptions: new PageComponent(
    "ManageSubscriptions",
    "/manage-subscriptions",
    true,
    true,
    true
  ),
  Memberships: new PageComponent(
    "Memberships",
    "/memberships",
    true,
    true,
    true
  ),
  NotFound: new PageComponent("NotFound", "", false, false, false),
  OperatorBookings: new PageComponent(
    "OperatorBookings",
    "/operator-bookings",
    true,
    true,
    true
  ),
  OperatorInvoices: new PageComponent(
    "OperatorInvoices",
    "/operator-invoices",
    true,
    true,
    true
  ),
  OperatorLogin: new PageComponent(
    "OperatorLogin",
    "/operator-login",
    false,
    false,
    false
  ),
  Organisations: new PageComponent(
    "Organisations",
    "/organisations",
    false,
    false,
    false
  ),
  Payment: new PageComponent("Payment", "/payment", true, true, true),
  PlayerNames: new PageComponent(
    "PlayerNames",
    "/player-names",
    true,
    true,
    true
  ),
  Print: new PageComponent("Print", "/print", true, true, true),
  Profile: new PageComponent("Profile", "/profile", true, true, true),
  ProfileOverview: new PageComponent(
    "ProfileOverview",
    "/profile-overview",
    true,
    true,
    true
  ),
  Queue: new PageComponent("Queue", "", false, false, false),
  Register: new PageComponent(
    "Register",
    "/register",
    true,
    true,
    true
  ),
  ShoppingBasket: new PageComponent(
    "ShoppingBasket",
    "/shopping-basket",
    true,
    true,
    true
  ),
  SignUp: new PageComponent(
    "SignUp",
    "/sign-up",
    false,
    false,
    false
  ),
  TemporaryAccess: new PageComponent(
    "TemporaryAccess",
    "/temporary-access",
    true,
    true,
    true
  ),
  VehicleRegistration: new PageComponent(
    "VehicleRegistration",
    "/vehicle-registration",
    true,
    true,
    true
  ),
};
