import pluralize from "pluralize";
import { convertDateToUnix } from "../date";
import TicketingService from "../../services/green4/TicketingService";
import { setError } from "../../services/ErrorService";
import queryString from "query-string";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range";

export const defaultProps = {
  product: null,
  productVariants: null,
  timePeriods: [],
  selectProduct: () => {},
  selectVariants: () => {},
  showImage: false,
  updateTempBasket: () => {},
  queryParams: "",
};

export const getDefaultState = () => {
  return {
    alert: "",
    attributes: [],
    availableDates: null,
    bookingReference: null,
    blockedDates: null,
    date: null,
    isAvailableDatesLoading: false,
    isBlockLoading: false,
    isSessionsLoading: false,
    isSubmitting: false,
    selectSeats: false,
    sessionsResult: null,
    showPanels: [0],
    showProduct: false,
    start: null,
    tempBasket: {},
    time: null,
  };
};

const updateProductState = (state, tempBasket, hasDependencies) => {
  if (hasDependencies)
    // revert state associated with the product
    // prevents updating tickets to something which is not valid for the time/date selected
    return {
      ...getDefaultState(),
      showProduct: state.showProduct,
      showPanels: state.showPanels,
      bookingReference: state.bookingReference,
      tempBasket: tempBasket,
    };
  else return { ...state, tempBasket: tempBasket };
};

export const panelHeading = (
  panelType,
  state,
  product,
  heading,
  isCouponProduct = false,
  couponProductSubTitle = ""
) => {
  let result = heading;
  switch (panelType) {
    case "session":
      if (state.time !== null) {
        result = state.time.format("HH:mm");
      } else if (
        state["0"] &&
        state["0"].Key &&
        state["0"].Key === "SessionStart"
      ) {
        result = moment(state["0"].Value).format("hh:mm a");
      }
      if (state.formattedPrice)
        result = result.concat(", " + state.formattedPrice);
      break;
    case "date":
      if (state.date !== null) {
        result = state.date.format(
          window.longDateFormat ?? "dddd DD MMMM YYYY"
        );
      }
      break;
    case "variant":
      if (!isTempBasketEmpty(state.tempBasket, product.Id)) {
        const variants = [];
        // filter out any hidden variants
        for (const key of Object.keys(
          state.tempBasket[product.Id]
        ).filter((key) => !key.includes("hidden"))) {
          variants.push(state.tempBasket[product.Id][key]);
        }
        if (variants.length > 0) {
          if (isCouponProduct) {
            result =
              couponProductSubTitle +
              " + " +
              pluralizeVariants(variants);
          } else {
            result = pluralizeVariants(variants);
          }
        }
      }
      break;
    case "membershipPaymentPlan":
      const selectedPaymentPlans = [];
      for (const key of Object.keys(state.selectedPaymentPlans)) {
        selectedPaymentPlans.push({
          Key: key,
          Value: state.selectedPaymentPlans[key],
        });
      }
      if (selectedPaymentPlans.length > 0) {
        const paymentPlanNames = [];

        // check if this is a grouped payment plan
        if (
          selectedPaymentPlans.length === 1 &&
          selectedPaymentPlans[0].Key === "Grouped"
        ) {
          const groupedMembershipPaymentPlan =
            state.groupedPaymentPlans.filter(
              (p) => p.Id === selectedPaymentPlans[0].Value
            );
          if (groupedMembershipPaymentPlan.length === 1) {
            paymentPlanNames.push(
              groupedMembershipPaymentPlan[0].Name
            );
          }
        } else {
          selectedPaymentPlans.forEach((plan) => {
            const productVariants = product.ProductVariants.filter(
              (v) => v.Id === plan.Key
            );

            const productVariant =
              productVariants.length === 1
                ? productVariants[0]
                : null;
            if (productVariant) {
              const membershipPaymentPlans =
                productVariant.MembershipPaymentPlans.filter(
                  (p) => p.Id === plan.Value
                );

              const membershipPaymentPlan =
                membershipPaymentPlans.length === 1
                  ? membershipPaymentPlans[0]
                  : null;
              if (membershipPaymentPlan) {
                paymentPlanNames.push(membershipPaymentPlan.Name);
              }
            }
          });
        }

        if (paymentPlanNames.length > 0) {
          result = paymentPlanNames.join(", ");
        }
      }
      break;
    default:
      break;
  }
  return result;
};

export const pluralizeVariants = (variants) => {
  let result = [];
  for (const variant of variants) {
    let name = variant.VariantTypeName || variant.Name;
    if (variant.Quantity > 1) name = pluralize(name);
    result.push(variant.Quantity + " " + name);
  }
  return result.join(", ");
};

export const updateTempBasket = (
  quantity,
  productVariant,
  state,
  productId,
  hasDependencies = false
) => {
  let result = state.tempBasket;
  productVariant.Quantity = quantity;

  if (quantity > 0) {
    if (state.tempBasket.hasOwnProperty(productId)) {
      // add variant to product key
      result = {
        ...state.tempBasket,
        [productId]: {
          ...state.tempBasket[productId],
          [productVariant.Id]: productVariant,
        },
      };
    } else {
      // add product key and variant
      result = {
        ...state.tempBasket,
        [productId]: { [productVariant.Id]: productVariant },
      };
    }
  } else {
    // remove variant from basket if it exists and quantity is less than 1
    if (
      state.tempBasket[productId] &&
      state.tempBasket[productId].hasOwnProperty(productVariant.Id)
    ) {
      let result = state.tempBasket;
      delete result[productId][productVariant.Id];
    }
  }
  return updateProductState(state, result, hasDependencies);
};

export const removeProductFromTempBasket = (state, productId) => {
  let result = state.tempBasket;
  if (state.tempBasket.hasOwnProperty(productId)) {
    result = state.tempBasket;
    delete result[productId];
  }
  return updateProductState(state, result, false);
};

export const isTempBasketEmpty = (tempBasket, productId = null) => {
  if (!tempBasket || Object.keys(tempBasket).length === 0) {
    // tempbasket totally untouched
    return true;
  } else if (productId !== null) {
    // check if specific product basket items exist
    return Object.keys(tempBasket[productId]).length === 0;
  }

  // if no product specified, and product keys exist, return false if any have variants added to basket
  for (const [, item] of Object.entries(tempBasket)) {
    if (Object.keys(item).length !== 0) {
      return false;
    }
  }
  return true;
};

export const tempBasketHasNonCouponRequests = (tempBasket) => {
  if (!tempBasket || Object.keys(tempBasket).length === 0) {
    // tempbasket totally untouched
    return false;
  }
  // check for any items in the temp basket which do not have CouponIds
  for (const [, item] of Object.entries(tempBasket)) {
    if (Object.keys(item).length > 0) {
      for (const property in item) {
        const request = item[property];
        if (
          !request.hasOwnProperty("CouponIds") ||
          request.CouponIds === null ||
          request.CouponIds.length === 0
        ) {
          return true;
        }
      }
    }
  }
  return false;
};

export const createProductRequests = (
  products,
  startDateTime = null,
  checkCrossSell = false,
  addProduct = false
) => {
  // returns an object containing addProduct and updateBookingProduct requests as arrays
  const addProductRequests = [];
  const updateBookingProductRequests = [];
  for (const [, product] of Object.entries(products)) {
    for (const [, variant] of Object.entries(product)) {
      const variantRequest = {
        Quantity: variant.Quantity,
      };

      //Use TotalPeople in variantRequest object for ProductRequest ticketing interface object as
      //AddProductRequest ticketing interface object doesn't contain TotalPeople
      if (!addProduct) {
        variantRequest.TotalPeople =
          variant.People * variant.Quantity;
      }
      if (variant.hasOwnProperty("DirectDebitMandateRequired")) {
        variantRequest.DirectDebitMandateRequired =
          variant.DirectDebitMandateRequired;
      }
      if (variant.hasOwnProperty("PayableByPaymentMethodId")) {
        variantRequest.PayableByPaymentMethodId =
          variant.PayableByPaymentMethodId;
      }
      if (variant.hasOwnProperty("MembershipPaymentPlanId")) {
        variantRequest.MembershipPaymentPlanId =
          variant.MembershipPaymentPlanId;
      }
      if (variant.hasOwnProperty("CouponIds")) {
        variantRequest.CouponIds = variant.CouponIds;
      }
      if (variant.hasOwnProperty("TopUpAmount")) {
        variantRequest.TopUpAmount = variant.TopUpAmount;
      }
      if (checkCrossSell) {
        variantRequest.CheckCrossSell = true;
      }
      if (variant.hasOwnProperty("StartDateRequired")) {
        variantRequest.StartDateRequired = variant.StartDateRequired;
      }
      if (variant.hasOwnProperty("ProductId")) {
        variantRequest.ProductId = variant.ProductId;
      }
      if (
        !variant.hasOwnProperty("BookingProductId") ||
        variant.BookingProductId === null
      ) {
        variantRequest.Id = variant.Id;
        if (startDateTime !== null) {
          variantRequest.StartDateTime =
            convertDateToUnix(startDateTime);
        }
        addProductRequests.push(variantRequest);
      } else {
        variantRequest.Id = variant.BookingProductId;
        updateBookingProductRequests.push(variantRequest);
      }
    }
  }
  return {
    addProductRequests: addProductRequests,
    updateBookingProductRequests: updateBookingProductRequests,
  };
};

export const addProductsToBooking = async (
  variants,
  startDateTime = null,
  attributes = [],
  checkCrossSell = false,
  readAdditionalProducts = false
) => {
  if (variants === {}) return;
  const productRequests = createProductRequests(
    { variants },
    startDateTime,
    checkCrossSell,
    true
  );

  if (
    productRequests.addProductRequests[0].hasOwnProperty(
      "DirectDebitMandateRequired"
    )
  ) {
    //Remove attribute if it already exists to avoid a duplicate attribute key error
    attributes = attributes.filter(
      (attr) => attr.Key !== "variantDirectDebitMandateRequired"
    );

    attributes.push({
      Key: "variantDirectDebitMandateRequired",
      Value:
        productRequests.addProductRequests[0]
          .DirectDebitMandateRequired,
    });
  }

  if (
    productRequests.addProductRequests[0].hasOwnProperty(
      "PayableByPaymentMethodId"
    )
  ) {
    //Remove attribute if it already exists to avoid a duplicate attribute key error
    attributes = attributes.filter(
      (attr) => attr.Key !== "variantPayableByPaymentMethodId"
    );

    attributes.push({
      Key: "variantPayableByPaymentMethodId",
      Value:
        productRequests.addProductRequests[0]
          .PayableByPaymentMethodId,
    });
  }

  if (
    productRequests.addProductRequests[0].hasOwnProperty(
      "StartDateRequired"
    )
  ) {
    //Remove attribute if it already exists to avoid a duplicate attribute key error
    attributes = attributes.filter(
      (attr) => attr.Key !== "StartDateRequired"
    );

    attributes.push({
      Key: "StartDateRequired",
      Value: productRequests.addProductRequests[0].StartDateRequired,
    });
  }

  if (
    productRequests.addProductRequests[0].hasOwnProperty("ProductId")
  ) {
    //Remove attribute if it already exists to avoid a duplicate attribute key error
    attributes = attributes.filter(
      (attr) => attr.Key !== "ProductId"
    );

    attributes.push({
      Key: "ProductId",
      Value: productRequests.addProductRequests[0].ProductId,
    });
  }

  const readBooking = true;
  const readBookingProducts = true;
  const result = await TicketingService.addProducts(
    productRequests.addProductRequests,
    attributes,
    readBooking,
    readBookingProducts,
    readAdditionalProducts
  ).then((response) => {
    const data = response.data;
    if (!data.Success) {
      setError("Error", data.ErrorMessage);
    }
    return data;
  });

  return result;
};

export const updateBookingProductQuantity = async (
  updateBookingProductRequests
) => {
  const result = await TicketingService.updateBookingProductQuantity(
    updateBookingProductRequests
  ).then((response) => {
    const result = response.data;
    if (result.Success) {
      return result;
    } else {
      setError("Error", result.ErrorMessage);
    }
  });
  return result;
};

export const setQueryParameterState = (location) => {
  const { search } = location;
  const query = queryString.parse(search);
  // product query params:
  // df = 'date from' product only availble from this date
  // dr = 'date range' ('0' will lock to selected date, '1' will allow a selection of initial date and 1 day after)
  // da = 'date alert' alert user to the from date
  // hs = 'hide sessions'
  // ps = 'pre select' date given in df value
  // block = code for event block. Only for event products
  const queryParams = {
    dateFormat: "YYYYMMDD", // ISO 8601:1981 Basic date format (used to keep URL short)
    dateRange: query && query.dr,
    endDate: undefined,
    fromDate: undefined,
    hasDateAlert: query.da && query.da === "1" ? true : false,
    hasDateRange:
      query && query.dr && typeof query.dr === "string"
        ? true
        : false,
    hasDateLocked:
      query && query.dr && query.dr === "0" ? true : false,
    hasFromDate:
      query &&
      query.df &&
      typeof query.df === "string" &&
      moment(query.df, "YYYYMMDD", true).isValid()
        ? true
        : false,
    hideSessions: null, // comma seperated values
    isEndDateInPast: null,
    isFromDateInFuture: null,
    category: query && query.category,
    product: query && query.product,
    membership: query && query.membership,
    hasPreselectDate:
      query &&
      query.ps &&
      typeof query.ps === "string" &&
      moment(query.ps, "YYYYMMDD", true).isValid()
        ? true
        : false,
    areaCode: query && query.area,
    blockCode: query && query.block,
  };

  queryParams.fromDate =
    queryParams.hasFromDate && query.df
      ? moment(query.df, queryParams.dateFormat)
      : undefined;

  queryParams.dateSelected = query.d
    ? moment.utc(query.d, queryParams.dateFormat)
    : undefined;

  queryParams.isFromDateInFuture = queryParams.hasFromDate
    ? moment(query.df, queryParams.queryDateFormat).isAfter(moment())
    : null;

  if (queryParams.hasDateRange) {
    if (queryParams.hasFromDate) {
      queryParams.endDate = queryParams.fromDate.clone();
    } else {
      queryParams.endDate = moment();
    }
    queryParams.endDate = queryParams.endDate
      .add(queryParams.dateRange, "days")
      .endOf("day");
    queryParams.isEndDateInPast = queryParams.endDate.isBefore(
      moment()
    );
  }

  if (query && query.hs) {
    queryParams.hideSessions = query.hs.split(",");
    queryParams.hideSessions = queryParams.hideSessions.map(
      (session) => parseInt(session, 10)
    );
  }

  //check if the pre selected date is within the date range given by the df and dr values.
  if (queryParams.hasPreselectDate) {
    queryParams.preselectedDate = query.ps
      ? moment(query.ps, queryParams.dateFormat)
      : undefined;

    let dateFromCompareCheck = true;
    let endDateCompareCheck = true;
    //Check if pre selected date is after the from date
    if (queryParams.hasFromDate) {
      dateFromCompareCheck = queryParams.fromDate.isSameOrBefore(
        queryParams.preselectedDate
      );
    }

    //Check if pre selected date is before the end date calculated from the date range
    if (queryParams.hasDateRange) {
      endDateCompareCheck = queryParams.endDate.isAfter(
        queryParams.preselectedDate
      );
    }

    //Check if pre selected date is on or after today
    let isOnOrAfterTodayCheck =
      queryParams.preselectedDate.isSameOrAfter(moment(), "date");

    queryParams.preselectDateValid =
      dateFromCompareCheck &&
      endDateCompareCheck &&
      isOnOrAfterTodayCheck;
  }

  //Get the variant IDs and Quantities heading into the additional products page
  const guidPattern = new RegExp(
    "^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$",
    "i"
  );

  let checker = true;
  let count = 1;

  let productRequests = [];
  while (checker) {
    checker = false;
    let quantityQueryName = `q${count}`;
    let variantQueryName = `v${count}`;
    if (
      query[quantityQueryName] &&
      query[variantQueryName] &&
      query[quantityQueryName].match(/^\d+$/) &&
      query[variantQueryName].match(guidPattern)
    ) {
      checker = true;
      count++;
      productRequests.push({
        Id: query[variantQueryName],
        Quantity: query[quantityQueryName],
      });
    }
  }
  queryParams.productRequests =
    productRequests.length > 0 ? productRequests : undefined;

  return { query: query, queryParams: queryParams };
};

export const availableFromDate = (
  queryParams,
  timezone = "Europe/London" // TODO: replace hardcoded timezone with timezone of venue
) => {
  //List of moment timezones: https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
  if (
    queryParams.hasFromDate &&
    (queryParams.hasDateLocked || queryParams.isFromDateInFuture)
  ) {
    return convertDateTimeToServerTimezone(
      moment(queryParams.fromDate, queryParams.dateFormat),
      timezone
    );
  }

  return convertDateTimeToServerTimezone(moment(), timezone);
};

export const convertDateTimeToServerTimezone = (
  date,
  timezone = "Europe/London" // TODO: replace hardcoded timezone with timezone of venue
) => {
  let serverTimeZoneDate = date.clone().tz(timezone);

  return serverTimeZoneDate.endOf("day");
};

export const isFixedEndDateMembershipPeriod = (period) => {
  return membershipPeriod(period) === "FixedEndDate";
};

export const isFourWeeksMembershipPeriod = (period) => {
  return membershipPeriod(period) === "FourWeeks";
};

export const isMonthMembershipPeriod = (period) => {
  return membershipPeriod(period) === "Month";
};

export const isYearMembershipPeriod = (period) => {
  return membershipPeriod(period) === "Year";
};

export const isIndefiniteMembershipPeriod = (period) => {
  return membershipPeriod(period) === "Indefinite";
};

export const membershipPeriod = (period) => {
  switch (period) {
    case 1:
      return "FixedEndDate";
    case 2:
      return "FourWeeks";
    case 3:
      return "Month";
    case 4:
      return "Year";
    case 5:
      return "Indefinite";
    default:
      return "None";
  }
};
