import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseIcon } from "../icons/icons.jsx";
import "./modal.scss";

import { Translation } from "react-i18next";

class Modal extends React.Component {
  componentDidMount() {
    !this.props.positionRelative &&
      document.body.classList.add("modal-open");
  }
  componentWillUnmount() {
    // remove modal open class if there are no other modals open
    if (document.querySelectorAll(".modal-content").length <= 1) { //.modal class usually gives count how many times being used in divs, modal-content always give 1 count for 1 modal and so on as number of modals opend
      document.body.classList.remove("modal-open");
    }
  }
  
  closeButtonClick() {
    if (this.props.modalCloseButtonClick !== null)
      this.props.modalCloseButtonClick();
  }
  render() {
    const {
      children,
      className,
      hideScrollBar,
      modalCloseButtonClick,
      positionRelative,
      showBackdrop,
      small,
      title,
      titleAsHeader,
      onTop
    } = this.props;

    const modalWrapperStyle = `modal 
              ${!showBackdrop && "pe-none no-backdrop"} 
              ${small && "modal-small"} 
              ${positionRelative && "position-relative"}
              ${hideScrollBar && "overflow-hidden"}
              ${onTop && "on-top"}`;

    const modalDialogStyle = `modal-dialog modal-dialog-centered 
                ${className} 
                ${!showBackdrop && "pe-none"}
                `;

    return (
      <Translation ns="ui">
        {(t) => (
          <>
            {showBackdrop && (
              <div className="modal-backdrop fade show"></div>
            )}
            <div
              className={modalWrapperStyle}
              tabIndex="-1"
              aria-modal="true"
              style={{ display: "block" }}
            >
              <div className={modalDialogStyle} role="document">
                <div className="modal-content">
                  {(title || modalCloseButtonClick !== null) && (
                    <div className="modal-header">
                      {titleAsHeader ? (
                        <h3 className="modal-title">{title}</h3>
                      ) : (
                        <p className="modal-title">{title}</p>
                      )}
                      {modalCloseButtonClick !== null && (
                        <button
                          type="button"
                          className="close btn"
                          aria-label={t("close", "Close")}
                          onClick={() => this.closeButtonClick()}
                        >
                          <FontAwesomeIcon
                            icon={CloseIcon}
                            aria-hidden="true"
                            className="closeIcon"
                          />
                        </button>
                      )}
                    </div>
                  )}
                  {children}
                </div>
              </div>
            </div>
          </>
        )}
      </Translation>
    );
  }
}

Modal.defaultProps = {
  className: "",
  modalCloseButtonClick: null,
  positionRelative: false,
  showBackdrop: true,
  small: false,
  title: "",
  titleAsHeader: true,
  hideScrollBar: false,
  priority: false,
};

export default Modal;
