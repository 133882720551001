// default icons from fontAwesome
// https://fontawesome.com/how-to-use/on-the-web/using-with/react
// import global icons to be used below

import {
  faAngleDown,
  faAngleUp,
  faBarcode,
  faCalendarAlt,
  faChair,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faClipboard,
  faClock,
  faCreditCard,
  faEnvelopeOpen,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faForward,
  faInfoCircle,
  faMinus,
  faMobileAlt,
  faPlus,
  faPrint,
  faSave,
  faSearch,
  faShoppingBasket,
  faSignInAlt,
  faSignOutAlt,
  faTicketAlt,
  faTimesCircle,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

export {
  faAngleDown as AngleDown,
  faAngleUp as AngleUp,
  faBarcode as Barcode,
  faCalendarAlt as CalendarIcon,
  faChair as ChairIcon,
  faCheck as CheckIcon,
  faCheckCircle as SuccessIcon,
  faChevronLeft as BackIcon,
  faChevronRight as NextIcon,
  faClipboard as ClipboardIcon,
  faClock as ClockIcon,
  faCreditCard as CreditCardIcon,
  faEnvelopeOpen as EnvelopeOpenIcon,
  faExclamationTriangle as DangerIcon,
  faEye as EyeIcon,
  faEyeSlash as EyeSlashIcon,
  faForward as Forward,
  faInfoCircle as InfoIcon,
  faMinus as MinusIcon,
  faMobileAlt as MobileIcon,
  faPlus as PlusIcon,
  faPrint as PrintIcon,
  faSave as SaveIcon,
  faSearch as SearchIcon,
  faShoppingBasket as BasketIcon,
  faSignInAlt as SignInIcon,
  faSignOutAlt as SignOutIcon,
  faTicketAlt as TicketIcon,
  faTimesCircle as CloseIcon,
  faUser as UserIcon,
  faUserPlus as UserPlusIcon,
};
